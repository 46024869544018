<template>
  <section class="hero hero-emphasis is-medium is-bold is-light has-text-centered">
    <div class="hero-body">
        <h1 class="title">Better. Together.</h1>
        <h2 class="subtitle">Great results come from great partnerships.
          <br>
          <br>Our partners are the leading experts in their fields.
        </h2>

        <div class="container">

        <div class="tile is-ancestor">

          <div class="tile is-parent">
            <div class="box">
              <div class="vendor-logo">
                <img src="/img/logo-convergio.png">
              </div>

              <h3 class="is-size-5">Convergio</h3>
              <h4 class="is-size-6">IT/OT Integration Specialists</h4>
              <p>Convergio offer complete IT/OT integration from consulting, analysis and project management, through to design, development, implementation, integration and training.</p>
            </div>
          </div>

          <div class="tile is-parent">
            <div class="box">
              <div class="vendor-logo">
                <img src="/img/logo-osisoft.png">
              </div>
              <h3 class="is-size-5">OSIsoft</h3>
              <h4 class="is-size-6">Operational Data Infrastructure</h4>

              <p>OSIsoft provides the market leading data infrastructure platform which we build use to build operational solutions.</p>
            </div>
          </div>

          <!-- <div class="tile is-parent">
            <div class="box">
              <div class="vendor-logo">
                <img src="/img/logo-esri.png">
              </div>

              <h3 class="is-size-5">Esri</h3>
              <h4 class="is-size-6">The Science of Where</h4>

              <p>Esri provide mapping and location intelligence technologies which we integrate into clients solutions.</p>
            </div>
          </div> -->

          <div class="tile is-parent">
            <div class="box">
              <div class="vendor-logo">
                <img src="/img/logo-microsoft.png">
              </div>

              <h3 class="is-size-5">Microsoft</h3>
              <h4 class="is-size-6">Powering Enterprise</h4>

              <p>We use Microsoft technologies to deliver unparalleled enterprise integration and cloud services with global coverage.</p>
            </div>
          </div>

          <div class="tile is-parent">
            <div class="box">
              <div class="vendor-logo">
                <img src="/img/logo-unified.png">
              </div>

              <h3 class="is-size-5">Unified</h3>
              <h4 class="is-size-6">Delivering Digital Solutions</h4>
              <p>Unified offers complete digital solutions from consulting, analysis and project management, through to design, development, implementation, integration and training.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'partners'
}
</script>

<style scoped>
.vendor-logo {
  padding: 50px;
  min-height: 150px;

  transition: all 0.2s ease;
  transition-property: transform, opacity, color, border-color, background-color;
}

.vendor-logo:hover {
  transform: scale(1.2);
}

.vendor-logo img {
  max-height: 100px;
  padding:0px;
}

h3 {
  font-weight: bold;
}

h4 {
  font-weight: bold;
  padding-bottom: 1em;
}
</style>
